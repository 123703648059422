import React, { useState,useEffect,useContext,useRef,ReactNode } from "react";
import { ReactComponent as XIcon } from 'assets/x.svg';
import { DateTime } from "luxon";
import toast from "react-hot-toast";
import { ReactComponent as HangUpIcon } from "assets/phone-hang-up.svg";
import { ReactComponent as LogsIcon } from "assets/logs.svg";
import { Audio, TelnyxRTCContext, useNotification } from "@telnyx/react-client";
import SidekickCallsContent from "../sidekick-calls-content";
import Stopwatch from "./stopwatch";
import SidekickLogsModal from "../sidekick-logs-modal";
import ReportIssuesModal from "../report-issues-modal";
import MicAudioComponent from "../mic-audio-component";
import OwnMicComponent from "../own-mic-component";
import AudioTestComponent from "../audio-test-component";
import KeypadComponent from "./keypad-component";
import { handleTelnyxEvent } from "./telnyx-logger";
import { useAuth } from "routes/authProvider";
import { HangUpDataType } from "./sidekick-types";


export default function SidekickWaitingRoom() {
  const { data } = useAuth();
  
  const [hangUpData, setHangUpData] = useState<HangUpDataType | null>(null);

  const [initWebCallData, setInitWebCallData] = useState<null | any>(null); //type TBD!!
  const [microphoneName, setMicrophoneName] = useState<string | null>(null);
  const [microphoneStatus, setMicrophoneStatus] = useState<string | null>(null);
  const [micData, setMicData] = useState<any>(null);
  const [showMicData, setShowMicData ] = useState<boolean>(false);
  
  const [startOfHoldTime, setstartOfHoldTime] = useState<string | null>();
  const [startOfIVRTime, setStartOfIVRTime] = useState<string | null>();
  const [startOfHumanConversation, setStartOfHumanConversation] = useState<string | null>();
  const client = useContext(TelnyxRTCContext);
  const [isOnLine, setIsOnLine] = useState<boolean>(false);
  const [testOk, setTestOk] = useState<boolean>(false);
  
  const [callData, setCallData] = useState<any>(null);

  const [cStatus, setCStatus] = useState<string[]>([]);

  const [status, setStatusText] = useState<string[]>([]);
  const [isMicrophoneAllowed, setIsMicrophoneAllowed] = useState(false);
  const [rightPanelOpen, setRightPanelOpen] = useState<boolean>(false);
  const [issueSubmitted, setIssueSubmitted] = useState<boolean>(false);

  const [userActions, setUserActions] = useState<string[]>([]);
  const [fesponses, setResponses] = useState<string[]>([]);
  const [telnyxEvents, setTelnyxEvents] = useState<
    { type: string; ev: Element | JSX.Element | React.ReactNode; msg?: string }[]
  >([]);
  const [lastStatus, setLastStatus] = useState<ReactNode>(
    <strong>Connecting</strong>
  );


  useEffect(() => {
    if (callData?.call?.state === "ringing") {
      //console.log('call', callData);
      
      callData?.call?.answer();
      handleTelnyxEvent('auto-answer', {
        custom:true,
        date: (new Date()).toISOString(),
      }, data?.user?.email)
    }
  }, [callData]);


  useEffect(() => {
    if (client) {
      client.enableMicrophone();
      client.on("telnyx.ready", (e) => {
        handleTelnyxEvent('telnyx.ready', e, data?.user?.email)
      });
      client.on("telnyx.error", (e) => {
        handleTelnyxEvent('telnyx.error', {...e, ...{
          callSate: callData?.call?.state ?? '',
        }}, data?.user?.email)
      });
      client.on("telnyx.notification", (e) => {
        if (e?.type === "callUpdate" || e?.method === "callUpdate") {
          if (typeof e.call !== 'undefined') {
            setCallData({call: e.call, timestamp: (new Date()).toISOString()});
            handleTelnyxEvent(`telnyx.notification received, setting notification.call as ${e.call.state}`, {
              custom:true,
              date: (new Date()).toISOString(),
            }, data?.user?.email)

          }else{
            handleTelnyxEvent('telnyx.notification received but notification.call is undefined', {
              custom:true,
              date: (new Date()).toISOString(),
            }, data?.user?.email)
          }
        }
        handleTelnyxEvent('telnyx.notification', e, data?.user?.email)
      });
      client.on("telnyx.socket.open", (e) => {
        handleTelnyxEvent('telnyx.socket.open', e, data?.user?.email)
      });
      client.on("telnyx.socket.close", (e) => {
        handleTelnyxEvent('telnyx.socket.close', {...e, ...{
          callSate: callData?.call?.state ?? '',
        }}, data?.user?.email)
      });
      client.on("telnyx.socket.error", (e) => {
        handleTelnyxEvent('telnyx.socket.error', {...e, ...{
          callSate: callData?.call?.state ?? '',
        }}, data?.user?.email)
      });
      client.on("telnyx.socket.message", (e) => {
        if (e?.type === "callUpdate" || e?.method === "callUpdate") {
          if (typeof e.call !== 'undefined') {
            setCallData({call: e.call, timestamp: (new Date()).toISOString()});
            handleTelnyxEvent('telnyx.socket.message received, settings notification.call as "active call"', {
              custom:true,
              date: (new Date()).toISOString(),
            }, data?.user?.email)

          } else {
            handleTelnyxEvent('telnyx.socket.message received but notification.call is undefined', {
              custom:true,
              date: (new Date()).toISOString(),
            }, data?.user?.email)
          }
        }
        handleTelnyxEvent('telnyx.socket.message', {...e, ...{
          callSate: callData?.call?.state ?? '',
        }}, data?.user?.email)
        if(e?.method === 'telnyx_rtc.bye') {

          let dialogParams = e?.params?.dialogParams
          if( typeof dialogParams === 'string' ){
            try {
              dialogParams = JSON.parse(e?.params?.dialogParams)
            } catch (error) {
              console.error(error);
            }
          }
          
          const customHeaders = dialogParams?.custom_headers || [];
          const hangupCauseHeader = customHeaders.find(header => header.name === "X-sk_hangup_cause")?.value ?? null; 
          const hangupCodeHeader = customHeaders.find(header => header.name === "X-sk_hangup_code")?.value ?? null; 
          const sipCode = (e?.params?.sipCode ?? '')?.toString()

          setHangUpData({
            hangupCauseHeader,
            hangupCodeHeader: hangupCodeHeader?.toString(),
            sipCode,
          })
        }
      });
      if (client?.checkPermissions) {
        handleTelnyxEvent('requesting microphone permissions to the browser.' , {
          custom:true,
          date: (new Date()).toISOString(),
        }, data?.user?.email)
        client?.checkPermissions(true, false);
      }
    }
  }, [client]);

  useEffect(() => {
    if (client) {
      client.enableMicrophone();
    }

    const checkMicrophonePermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const audioTracks = stream.getAudioTracks();
        if (audioTracks.length > 0) {
          const audioTrack = audioTracks[0];
          let micData = {
            'Microphone:': audioTrack.label,
            'Device ID:': audioTrack.id,
            'Ready state:': audioTrack.readyState,
            'Kind:': audioTrack.kind,
            'Capabilities:': audioTrack.getCapabilities(),
            'Constraints:': audioTrack.getConstraints(),
            'Current settings:': audioTrack.getSettings(),
          }
          handleTelnyxEvent('checkMicrophonePermission micAllowed! setting MicData', {
            custom:true,
            date: (new Date()).toISOString(),
            micAllowed: true,
            callSate: callData?.call?.state ?? '',
            micData
          }, data?.user?.email)
          setMicData(micData)
          setMicrophoneName(audioTrack.label);
        }
        stream.getTracks().forEach((track) => track.stop()); // Release the stream
    
        setIsMicrophoneAllowed(true);
        setMicrophoneStatus('Ready (No errors found)')
      } catch (error: any) {
        setIsMicrophoneAllowed(false);
        let errorMessage = '';
        if (error?.name === 'NotAllowedError') {
          errorMessage = 'The user denied permission to access the microphone.';
        } else if (error?.name === 'NotFoundError') {
          errorMessage = 'No audio devices found.';
        } else if (error?.name === 'SecurityError') {
          errorMessage = 'Cannot access the microphone due to security issues.';
        } else {
          errorMessage = 'An error occurred while accessing the microphone, check logs for more details.';
        }
        handleTelnyxEvent('checkMicrophonePermission False', {
          custom:true,
          micAllowed: false,
          callSate: callData?.call?.state ?? '',
          date: (new Date()).toISOString(),
          errorMessage
        }, data?.user?.email)
        setMicrophoneStatus(errorMessage);
        toast.error(errorMessage, {
          iconTheme: {
            primary: "#F36F82",
            secondary: "#ffffff",
          },
        });
      }
    };
    checkMicrophonePermission();

    const handleOnlineStatusChange = () => {
      handleTelnyxEvent('handleOnlineStatusChange', {
        custom:true,
        callSate: callData?.call?.state ?? '',
        date: (new Date()).toISOString(),
        isOnline: navigator.onLine,
        msg: `navigator.onLine: ${(navigator.onLine ? "Yes" : "No")}`
      }, data?.user?.email)
    };

    const handlePermissionChange = (event) => {
      if (event.state === 'granted') {
        checkMicrophonePermission();
        handleTelnyxEvent('handlePermissionChange to granted', {
          custom:true,
          callSate: callData?.call?.state ?? '',
          hasPermission: true,
          date: (new Date()).toISOString(),
        }, data?.user?.email)
        setIsMicrophoneAllowed(true);
      } else {
        setIsMicrophoneAllowed(false);
        handleTelnyxEvent('handlePermissionChange to not granted', {
          custom:true,
          hasPermission: false,
          callSate: callData?.call?.state ?? '',
          date: (new Date()).toISOString(),
        }, data?.user?.email)
      }
      toast.success("Permissions have changed. Please reload the page.", {
        iconTheme: {
          primary: "#4a43cd",
          secondary: "#ffffff",
        },
      });
    };
    const handleDeviceChange = () => {
      checkMicrophonePermission();
    };
    const permissionStatus = navigator.permissions.query({name: 'microphone' as PermissionName});
    permissionStatus.then((status) => {
      status.addEventListener('change', handlePermissionChange);
    });
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    setIsOnLine(navigator.onLine);
  }, [navigator.onLine, navigator]);

  const [issuesModalOpen, setIssuesModalOpen] = useState<boolean>(false);

  const [showKeypad, setShowKeypad] = useState<boolean>(false);

  const toggleKeypad = () => {
    setShowKeypad(!showKeypad);
  };

  const sendDTMF = (button: string) => {
    if (callData?.call) {
      handleTelnyxEvent('userAction: sendDTMF', {
        custom:true,
        date: (new Date()).toISOString(),
        button
      }, data?.user?.email)
      callData?.call?.dtmf(button);
    }
  };

  const handleStatusChange = (status: string[]) => {
    setStatusText(status);
  };

  const copyLogs = () => {
    const micText = `Mic enabled: ${isMicrophoneAllowed ? "Yes" : "No"}`;
    const clientText = `Client enabled: ${client?.connected ? "Yes" : "No"}`;
    const onlineText = `Online: ${isOnLine ? "Yes" : "No"}`;
    const statusText = `Status: ${status}`;
    const textToCopy = `${micText}\n${clientText}\n${onlineText}\n${statusText}\n`;
    navigator.clipboard.writeText(textToCopy);
    toast.success("Copied to clipboard", {
      iconTheme: {
        primary: "#4a43cd",
        secondary: "#ffffff",
      },
    });
  };


  const getDevices = async () => {
    let result = await client?.getAudioInDevices();
    //console.log("Audio devices found:", result);
    //setAudioDevices(result);
  };



  return (
    <>
      <div className="w-full flex items-center jus gap-x-4 mt-4 mx-4">
        <div><strong className="text-xs text-superbill-jacarta">Mic enabled:</strong> {isMicrophoneAllowed ? <span className="text-xs text-green-700">Yes</span> : <span className="text-xs text-superbill-ultra-red-hover">No</span>}</div>
        <div><strong className="text-xs text-superbill-jacarta">Mic Name:</strong> <span className="text-xs text-green-700">{microphoneName}</span></div>
        <div><strong className="text-xs text-superbill-jacarta">Mic Status:</strong> <span className="text-xs text-green-700">{microphoneStatus}</span></div>
        <div><span className="text-xs hover-underline-animation cursor-pointer" onClick={() => {
          setShowMicData(!showMicData)
        }}>{showMicData ? 'Hide mic data' : 'Show mic data'}</span></div>
        
      </div>
      {showMicData ? 
        <div className="flex flex-col justify-center items-start p-4 bg-white border border-superpay-soap mt-4 mx-4 overflow-x-auto">
          <div className="w-full flex justify-end">
            <XIcon className=" w-4 h-4 mr-4 fill-superbill-slate-grey/50 hover:fill-superbill-slate-grey cursor-pointer ease-in-out duration-150 min-w-[14px] " onClick={() => {
              setShowMicData(false)
            }} />
          </div>
            {Object.keys(micData ?? {}).map((key, index) => {
              return (
                <div key={'micData_' + index} className="flex items-center gap-1">
                  <span className="text-xs text-superbill-jacarta">{key}</span>
                  <span className="text-xs text-green-700">{JSON.stringify(micData[key])}</span>
                </div>
              )
            })}
        </div>
       : null}

      {!testOk ? 
      <div className="w-full flex items-center jus gap-x-4 mt-4 mx-4 flex-col">
        {/* <AudioInputSelector/> experimental*/}
        <AudioTestComponent onSuccess={() => {
          if(!isMicrophoneAllowed) {
            toast.error("Please enable microphone access to proceed",{
              iconTheme: {
                primary: "#F36F82",
                secondary: "#ffffff",
              },
            })
          }
          setTestOk(true)
        }}/>
      </div> : null}

      {testOk ? 
          <div className="w-full h-full">
            <div className="font-sans relative text-center flex justify-center mt-1 flex-col">
              <div className="flex justify-start mt-4 mx-4">
                <div className="w-full flex items-center jus gap-x-4">
                  <OwnMicComponent />
                  {callData && callData?.call?.localStream && !['hangup', 'destroy'].includes(callData?.call?.state) ? (
                    <MicAudioComponent stream={callData?.call?.localStream} />
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end items-center mx-4 gap-x-1">
                <span className="text-xs flex items-center gap-x-2 p-2 bg-superbill-lavender rounded border border-superpay-soap">
                  <strong>Call State: {callData && callData?.call?.state ? callData?.call?.state : "No call"}</strong>
                  <em>(If you have been waiting for 3 minutes or more, submit the call as need to retry)</em>
                </span>
              </div>
              
              {callData?.call ? (
                <div
                  className={`flex justify-start mt-4 mx-4 bg-white p-3 rounded border border-superbill-soap shadow `}
                >
                  <button
                    className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 mr-4 disabled:bg-superbill-anti-flash-white"
                    onClick={() => {
                      setstartOfHoldTime((new Date).toISOString());
                    }}
                    disabled={!!startOfHoldTime}
                  >
                    Start of hold time
                    <div className="text-xs text-superbill-jacarta">
                      {startOfHoldTime}
                    </div>
                  </button>
                  <button
                    className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 mr-4 disabled:bg-superbill-anti-flash-white"
                    onClick={() => {
                      setStartOfIVRTime((new Date).toISOString());
                    }}
                    disabled={!!startOfIVRTime}
                  >
                    Start of IVR time
                    <div className="text-xs text-superbill-jacarta">
                      {startOfIVRTime}
                    </div>
                  </button>
                  <button
                    className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 mr-4 disabled:bg-superbill-anti-flash-white"
                    onClick={() => {
                      let newDate = DateTime.now();
                      setStartOfHumanConversation(newDate.toISO());
                    }}
                    disabled={!!startOfHumanConversation}
                  >
                    Start of human conversation
                    <div className="text-xs text-superbill-jacarta">
                      {startOfHumanConversation}
                    </div>
                  </button>
                </div>
              ) : null}
              <Audio stream={callData?.call?.remoteStream} />
            </div>
            {showKeypad ? (
              <KeypadComponent sendDTMF={sendDTMF}/>
            ) : null}
            <SidekickCallsContent
              hangUpData={hangUpData}
              client={client}
              user_email={data?.user?.email}
              showKeypad={showKeypad}
              wsCallData={callData}
              openIsuesModal={() => setIssuesModalOpen(true)}
              wsSetCallData={setCallData}
              toggleKeypad={toggleKeypad}
              startOfHoldTime={startOfHoldTime}
              setstartOfHoldTime={setstartOfHoldTime}
              startOfIVRTime={startOfIVRTime}
              setStartOfIVRTime={setStartOfIVRTime}
              startOfHumanConversation={startOfHumanConversation}
              setStartOfHumanConversation={setStartOfHumanConversation}
              setIssueSubmitted={setIssueSubmitted}
              initWebCallData={initWebCallData}
              setInitWebCallData={setInitWebCallData}
            />
          </div>
      : null}

      <ReportIssuesModal
        setIssueSubmitted={setIssueSubmitted}
        issueSubmitted={issueSubmitted}
        isOpen={issuesModalOpen}
        onClose={() => setIssuesModalOpen(false)}
        robodialerId={initWebCallData?.robodialer_id ?? ""}
        callId={initWebCallData?.call_id ?? ""}
        attempt={initWebCallData?.n_attempt ?? ""}
        lastStatus={lastStatus}
        isOnLine={isOnLine}
        clientConnected={client?.connected ?? false}
        cStatus={cStatus}
        userActions={userActions}
        fesponses={fesponses}
        telnyxEvents={telnyxEvents}
        isMicrophoneAllowed={isMicrophoneAllowed}
      />

      <SidekickLogsModal
        isOpen={rightPanelOpen}
        onClose={() => setRightPanelOpen(false)}
        handleStatusChange={handleStatusChange}
        copyLogs={copyLogs}
        isMicrophoneAllowed={isMicrophoneAllowed}
        lastStatus={lastStatus}
        isOnLine={isOnLine}
        client={client}
        cStatus={cStatus}
        userActions={userActions}
        fesponses={fesponses}
        telnyxEvents={telnyxEvents}
      />
      <div className="flex-[5]">
        {!rightPanelOpen ? (
          <>
            <div
              onClick={() => setRightPanelOpen(true)}
              className="font-jakarta text-sm font-semibold text-superbill-jacarta flex items-center cursor-pointer rounded-full hover:bg-superbill-anti-flash-white py-2 px-3 w-fit h-fit bg-white fixed border border-superbill-soap border-b-0.5 bottom-5 right-10 drop-shadow ease-in-out duration-150 "
            >
              <LogsIcon className="mr-2" /> Logs
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
