import React, { useEffect, useMemo, useRef, useState } from "react";
import Loader from "components/Loader";
import { ReactComponent as ExclamationIcon } from "assets/exclamation.svg";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { ReactComponent as NoPatients } from "assets/no-patient-results.svg";
import { ReactComponent as CloseIcon } from "assets/x.svg";

import { archiveResults, retryPmsCalls } from "../apis/robodialer";
import { Tooltip } from "@material-tailwind/react";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import RetryCallsModal from "./retry-calls-modal";
import Select from "react-select";


interface FormType {
  gotoInput: number;
}

interface AttemptResult {
  question: string;
  answer: string;
  type: string;
}
interface Call {
  attempt_results: Record<string, AttemptResult>;
  callId: string;
  script_questions: any;
  attempt_data: any;
  transcript?: string;
  supportTeamOutcome: string;
  first_name?: string;
  last_name?: string;
  script?: boolean;
}

type PMSResultsTableProps = {
  pmsResults: {
    calls: Call[]; //PMSPatientData[],
    limit: number;
    offset: number;
    patientName?: string;
    callStatus?: string;
  };
  selectedPatient: string | null;
  setSelectedPatient: React.Dispatch<React.SetStateAction<string | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: (
    limit?: number,
    offset?: number,
    patientName?: string,
    status?: string
  ) => void;
  total: number;
  archived: boolean;
};

function PMSResultsTable({
  archived,
  pmsResults,
  setSelectedPatient,
  refresh,
  total,
  setLoading,
}: PMSResultsTableProps) {
  const [isArchiving, setIsArchiving] = useState<boolean>(false);
  const [isRetrying, setIsRetrying] = useState<boolean>(false);
  const [isRetryModalOpen, setIsRetryModalOpen] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortBy, setSortBy] = useState<string | null>(null);
  const navigate = useNavigate();

  const patientNameRef = useRef<HTMLInputElement>(null);
  const statusRef = useRef<HTMLSelectElement>(null);

  const createAnswerMap = (call: Call) => {
    const answerMap: Record<string, string> = {};
    const questions = call.script_questions ?? {};
    const summary = call.attempt_data?.summary ?? "-";
    const tags = call.attempt_data?.tags ?? "-";
    Object.keys(questions).forEach((key) => {
      const qObject = questions[key];
      if (qObject && qObject.question) {
        let answer = call.attempt_results[qObject.questionId]?.answer;
        // Convert boolean values to "Yes" or "No"
        if (typeof answer === "boolean") {
          answer = answer ? "Yes" : "No";
        }
        // Display "-" if the answer is null
        answerMap[`${qObject.questionId}.${qObject.question}`] =
          answer === null ? "-" : answer || "";
      }
    });
    answerMap["Summary"] = summary;
    answerMap["Tags"] = tags;

    return answerMap;
  };

  const [selectAll, setSelectAll] = useState(false);

  const [selectedItems, setSelectedItems] = useState(new Set<string>());

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set<string>(prevSelectedItems);
      Object.keys(pmsResults?.calls ?? {}).forEach((index) => {
        const itemKey = `${pmsResults?.calls[index].callId}`;
        if (!newSelectedItems.has(itemKey)) {
          newSelectedItems.add(itemKey);
        } else {
          newSelectedItems.delete(itemKey);
        }
      });
      return newSelectedItems;
    });
  };

  const handleSelectItem = (callId: string) => {
    const key = `${callId}`;
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set<string>(prevSelectedItems);
      if (newSelectedItems.has(key)) {
        newSelectedItems.delete(key);
      } else {
        newSelectedItems.add(key);
      }
      return newSelectedItems;
    });
  };

  const [searchValue, setSearchValue] = useState("");

  const filteredAndSortedPatients = useMemo(() => {
    const convertedArray = Object.keys(pmsResults.calls ?? {})?.map((key) => ({
      //id: key,
      ...pmsResults.calls[key],
    }));

    let filteredPatients = [...convertedArray];

    if (searchValue) {
      filteredPatients = filteredPatients.filter(
        (patient) =>
          //patient[searchType].toLowerCase().includes(searchValue.toLowerCase())
          patient["first_name"]
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          patient["last_name"].toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    filteredPatients.sort((a, b) => {
      if (
        a?.supportTeamOutcome === "processing" &&
        b?.supportTeamOutcome !== "processing"
      ) {
        return -1;
      }
      if (
        a?.supportTeamOutcome !== "processing" &&
        b?.supportTeamOutcome === "processing"
      ) {
        return 1;
      }
      const dateA = DateTime.fromJSDate(
        new Date(a.assignedAt ?? a.date_created)
      ).toFormat("MM-dd-yyyy");
      const dateB = DateTime.fromJSDate(
        new Date(b.assignedAt ?? b.date_created)
      ).toFormat("MM-dd-yyyy");
      if (dateA === null && dateB !== null) {
        return 1;
      }
      if (dateA !== null && dateB === null) {
        return -1;
      }
      if (dateA !== null && dateB !== null && dateA !== dateB) {
        if (dateB > dateA) {
          return 1;
        } else if (dateB < dateA) {
          return -1;
        }
      }
      const nameA = `${a.last_name} ${a.first_name}`;
      const nameB = `${b.last_name} ${b.first_name}`;
      return nameA.localeCompare(nameB);
    });
    return filteredPatients;
  }, [sortBy, sortDirection, searchValue /* searchType */]);

  const { setValue, control } = useForm<FormType>();

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event.target.value);

    const newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      setValue("gotoInput", newValue);
      if (
        0 <= newValue - 1 &&
        newValue - 1 < Math.ceil(total! / pmsResults?.limit)
      ) {
        refresh(
          (newValue - 1) * pmsResults?.limit,
          pmsResults.limit,
          patientNameRef.current?.value || "",
          statusRef.current?.value || ""
        );
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  };

  const retryCalls = async () => {
    setIsRetrying(true);
    const response = await retryPmsCalls(Array.from(selectedItems));
    const { retryCategories } = response.data;
    const toastMessages: string[] = [];
    const patientNames: { [key: string]: string[] } = {};

    const computeToastMessage = (category, callPatientNames: string[]) => {
      const pluralS = callPatientNames.length > 1 ? "s" : "";
      let callsClause = `${
        callPatientNames.length
      } call${pluralS} (${callPatientNames.join(", ")}) `;
      let reasonClause = "";

      switch (category) {
        case "success":
          reasonClause = "successfully added to the queue.";
          break;
        case "completedInPastWeek":
          reasonClause =
            "could not be placed because successful calls cannot be retried within 7 days of completion.";
          break;
        case "alreadyRetrying":
          reasonClause = `could not be placed due to other existing call${pluralS} in progress for the same patient${pluralS}.`;
          break;
        case "currentlyPending":
          reasonClause = `could not be placed because the call${
            callPatientNames.length > 1 ? "s are" : " is"
          } already in progress.`;
          break;
        case "error":
          reasonClause =
            "could not be placed. This may be due to incomplete information in the PMS.";
          break;
      }

      return `${callsClause}${reasonClause}`;
    };

    for (const category in retryCategories) {
      patientNames[category] = [];
      for (const callID of retryCategories[category]) {
        patientNames[category].push(
          `${pmsResults.calls[callID].first_name} ${pmsResults.calls[callID].last_name}`
        );
      }

      if (patientNames[category].length > 0) {
        toastMessages.push(
          computeToastMessage(category, patientNames[category])
        );
      }
    }
    refresh(
      pmsResults.offset,
      pmsResults.limit,
      patientNameRef.current?.value || "",
      statusRef.current?.value || ""
    );
    setIsRetryModalOpen(false);
    setIsRetrying(false);
    toast(
      (t) => (
        <div className="flex flex-col">
          {toastMessages.map((message) => (
            <div key={message} className="flex flex-row">
              <p className="my-1.5">{message}</p>
            </div>
          ))}
          <CloseIcon
            className="absolute right-2 top-2 w-3 cursor-pointer"
            onClick={() => toast.dismiss(t.id)}
          />
        </div>
      ),
      {
        duration: Infinity,
        iconTheme: {
          primary: "#4a43cd",
          secondary: "#ffffff",
        },
      }
    );
  };

  useEffect(() => {
    setValue("gotoInput", pmsResults?.offset / pmsResults?.limit + 1);
    console.log("pmsResults", pmsResults);
    if (patientNameRef.current) {
      patientNameRef.current.value = pmsResults?.patientName ?? "";
    }
    if (statusRef.current) {
      statusRef.current.value = pmsResults?.callStatus ?? "";
    }
  }, []);

  let debounceTimer: NodeJS.Timeout;
  const debouncedFetch = (e) => {
    let status = e?.value || "";
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      refresh(
        0,
        pmsResults.limit,
        patientNameRef.current?.value || "",
        status || ""
      );
    }, 1500);
  };
  const callStatusOptions = [
    { value: "All", label: "All Call Statuses" },
    { value: "InProgress", label: "In Progress" },
    { value: "Success", label: "Success" },
    { value: "Failure", label: "Failure" },
  ];

  return (
    <>
      {Object.keys(filteredAndSortedPatients).length === 0 ? (
        <>
          {archived ? (
            <>
              <NoPatients className="w-full text-center mb-4" />
              <div className="font-sans w-full text-center items-center text-superpay-light-text-gray">
                No archived results available
              </div>
            </>
          ) : (
            <>
              <NoPatients className="w-full text-center mb-4" />
              <div className="font-sans w-full text-center items-center text-superpay-light-text-gray">
                No patient insurance verification results available
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="w-full p-2">
            <div className="flex items-center justify-start gap-x-2 w-full mt-2">
              <div className="w-full">
                <input
                  ref={patientNameRef}
                  type="text"
                  placeholder="Search by patient name"
                  defaultValue={pmsResults?.patientName ?? ""}
                  className="w-full rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:border-superbill-indigo focus:outline-none text-base border border-superbill-soap focus:bg-superbill-light-gray-background hover:bg-superbill-anti-flash-white disabled:bg-superbill-anti-flash-white p-2 font-sans"
                  onChange={debouncedFetch}
                />
              </div>
              <div className="w-full">
                {/* <select
                  ref={statusRef}
                  defaultValue={pmsResults?.callStatus || "All"}
                  className="w-full rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:border-superbill-indigo focus:outline-none text-base border border-superbill-soap focus:bg-superbill-light-gray-background hover:bg-superbill-anti-flash-white disabled:bg-superbill-anti-flash-white p-2 font-sans"
                  onChange={debouncedFetch}
                >
                  <option value="All">All Call Statuses</option>
                  <option value="InProgress">In Progress</option>
                  <option value="Success">Success</option>
                  <option value="Failure">Failure</option>
                </select> */}
                <Select
                  options={callStatusOptions}
                  value={callStatusOptions.find(opt => opt.value === (pmsResults?.callStatus || "All"))}
                  onChange={debouncedFetch}
                  isSearchable={false}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "1px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      borderColor: state.isFocused ? "#4a43cd" : "#D0D3F2",
                      boxShadow: state.isFocused ? "0 0 0 4px rgba(74, 67, 205, 0.1)" : "none",
                      backgroundColor: state.isDisabled ? "superbill-anti-flash-white" : "white",
                      "&:hover": {
                        backgroundColor: "superbill-anti-flash-white",
                      },
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isFocused ? "#F9F9FC" : "white",
                      color: "superbill-jacarta",
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full justify-between flex px-2 items-center font-sans h-[48px]">
            <div className="flex items-center">
              {!archived && (
                <button
                  className="flex items-center gap-x-2 bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold mr-3 py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 disabled:bg-superbill-anti-flash-white disabled:text-superbill-slate-grey/50"
                  onClick={async () => {
                    setIsRetryModalOpen(true);
                  }}
                  disabled={selectedItems.size == 0}
                >
                  Re-try
                </button>
              )}
              <button
                className="flex items-center gap-x-2 bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 disabled:bg-superbill-anti-flash-white disabled:text-superbill-slate-grey/50"
                disabled={isArchiving || selectedItems.size == 0}
                onClick={async () => {
                  setIsArchiving(true);
                  const { httpCode } = await archiveResults({
                    calls: Array.from(selectedItems),
                    archive: !archived,
                  });
                  setIsArchiving(false);
                  if (httpCode === 200) {
                    toast.success(
                      `Successfully ${!archived ? "archived" : "un-archived"}`,
                      {
                        iconTheme: {
                          primary: "#4a43cd",
                          secondary: "#ffffff",
                        },
                      }
                    );
                    refresh(
                      pmsResults.offset,
                      pmsResults.limit,
                      patientNameRef.current?.value || "",
                      statusRef.current?.value || ""
                    );
                  }
                }}
              >
                {!archived ? "Archive" : "Un-Archive"}
                {isArchiving ? <Loader size={12} /> : null}
              </button>
            </div>
            <button
              className="flex items-center gap-x-2 bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 disabled:bg-superbill-anti-flash-white disabled:text-superbill-slate-grey/50"
              onClick={async () => {
                navigate(
                  `/super-dial-dashboard/${
                    archived ? "" : "archived-"
                  }pms-insurance-verifications`
                );
              }}
            >
              {`Go to ${!archived ? "archived" : "un-archived"} calls`}
            </button>
          </div>
          <div className="flex w-full p-2 font-sans">
            <div className="flex-1 flex items-start justify-start overflow-x-auto border border-superbill-soap rounded">
              {/* Fixed Table */}
              <div className="overflow-hidden flex-shrink-0 border-r-2 border-r-superbill-soap">
                <table className="min-w-max">
                  <thead>
                    <tr>
                      <th className="p-2 bg-superbill-anti-flash-white w-[50px] h-[55px] truncate border-r border-superbill-soap">
                        <div className="flex w-full h-full justify-center items-center">
                          <input
                            type="checkbox"
                            className="rounded-sm checked:border-superbill-ultramarine"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </div>
                      </th>
                      <th className="p-2 bg-superbill-anti-flash-white w-[150px] h-[55px] truncate border-r border-superbill-soap text-left">
                        <span className="font-bold text-superbill-jacarta text-sm justify-start">
                          Date Verified
                        </span>
                      </th>
                      <th className="p-2 bg-superbill-anti-flash-white w-[130px] h-[55px] truncate border-r border-superbill-soap text-left">
                        <span className="font-bold text-superbill-jacarta text-sm">
                          Date of Birth
                        </span>
                      </th>
                      <th className="p-2 bg-superbill-anti-flash-white w-[130px] h-[55px] truncate border-r border-superbill-soap text-left">
                        <span className="font-bold text-superbill-jacarta text-sm">
                          First Name
                        </span>
                      </th>
                      <th className="p-2 bg-superbill-anti-flash-white w-[130px] h-[55px] truncate border-r border-superbill-soap text-left">
                        <span className="font-bold text-superbill-jacarta text-sm">
                          Last Name
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {Object.keys(pmsResults.calls ?? {})?.map((key, index) => {
                      let call = pmsResults.calls[key];
                      return (
                        <tr key={`fixed-body-${key}-${index}`}>
                          <td className="border-t border-r border-superbill-soap p-2 w-[50px] h-[55px] text-center">
                            <input
                              type="checkbox"
                              className="border border-superpay-light-text-gray rounded-sm checked:border-superbill-ultramarine"
                              checked={selectedItems.has(`${call?.callId}`)}
                              onChange={() => handleSelectItem(call?.callId)}
                            />
                          </td>
                          <td className="border-t border-r border-superbill-soap flex items-center p-2 flex-shrink-0 w-[150px] h-[55px] truncate">
                            <span
                              className={`${
                                ["success", "failure"].includes(
                                  (
                                    call?.supportTeamOutcome ?? ""
                                  )?.toLowerCase()
                                )
                                  ? "hover-underline-animation"
                                  : "text-superbill-slate-grey"
                              } text-base  cursor-pointer`}
                              onClick={() => {
                                setSelectedPatient(String(index));
                              }}
                            >
                              {DateTime.fromJSDate(
                                new Date(call?.createdAt)
                              ).toFormat("MMM d, yyyy")}
                            </span>
                            {call?.supportTeamOutcome === "Failure" ? (
                              <Tooltip
                                content={
                                  <span style={{ whiteSpace: "pre-line" }}>
                                    Failed
                                  </span>
                                }
                                className="bg-black py-1 px-2"
                              >
                                <ExclamationIcon className="child:fill-[#F46F83] min-w-[16px] max-w-[16px] ml-2" />
                              </Tooltip>
                            ) : null}
                          </td>
                          <td className="border-t border-r border-superbill-soap p-2 w-[130px] h-[55px] truncate ">
                            {call?.dob}
                          </td>
                          <td className="border-t border-r border-superbill-soap p-2 w-[130px] h-[55px] truncate ">
                            {call?.first_name}
                          </td>
                          <td className="border-t border-r border-superbill-soap p-2 w-[130px] h-[55px] truncate ">
                            {call?.last_name}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {/* Scrollable Table */}
              <div className="overflow-x-auto flex-1">
                <table className="min-w-max">
                  <thead>
                    <tr>
                      {Object.keys(
                        createAnswerMap(pmsResults.calls[0] ?? {})
                      )?.map((header, index) => (
                        <th
                          key={`scrollable-header-${header}-${index}`}
                          className="p-2 bg-superbill-anti-flash-white min-w-[150px] max-w-[300px] overflow-hidden text-clip whitespace-nowrap border-r border-superbill-soap h-[55px] text-sm text-superbill-jacarta"
                        >
                          <Tooltip
                            content={
                              <span style={{ whiteSpace: "pre-line" }}>
                                <span className="text-sm">{header}</span>
                              </span>
                            }
                            className="bg-black py-1 px-2 max-w-[200px]"
                          >
                            {header}
                          </Tooltip>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(pmsResults.calls ?? {})?.map((key, index) => {
                      let call = pmsResults.calls[key];
                      const answerMap = createAnswerMap(call);
                      return (
                        <tr key={index}>
                          {Object.keys(answerMap ?? {}).map((header, index) => (
                            <td
                              key={`scrollable-body-${header}-${index}`}
                              className="p-2 border-t border-superbill-soap min-w-[150px] max-w-[300px] overflow-hidden text-clip whitespace-nowrap border-r bg-white h-[55px]"
                            >
                              <Tooltip
                                content={
                                  <span style={{ whiteSpace: "pre-line" }}>
                                    <span className="text-sm">
                                      {answerMap[header] || ""}
                                    </span>
                                  </span>
                                }
                                className="bg-black py-1 px-2 max-w-[200px]"
                              >
                                {answerMap[header] || ""}
                              </Tooltip>
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end flex-row px-2 pt-2 font-sans">
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              onClick={async () => {
                await refresh(
                  Math.max(0, pmsResults.offset - pmsResults.limit),
                  pmsResults.limit,
                  patientNameRef.current?.value || "",
                  statusRef.current?.value || ""
                );
              }}
            >
              {pmsResults.offset === 0 ? <></> : <BackIcon />}
            </div>
            <div className="mx-2 flex items-center">
              <strong className="mr-1">
                <Controller
                  name="gotoInput"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      defaultValue={(
                        pmsResults.offset / pmsResults.limit +
                        1
                      ).toString()}
                      className={`px-1 h-6 flex items-center justify-center text-center ronded rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base ${
                        pmsResults.offset > 999
                          ? `w-16`
                          : pmsResults.offset > 99
                          ? `w-12`
                          : `w-8`
                      }`}
                      onBlur={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />
                  )}
                />
              </strong>{" "}
              {total ? (
                <>
                  {" "}
                  of {Math.ceil(total! / pmsResults?.limit).toLocaleString()}
                </>
              ) : (
                <Loader size={12} />
              )}
            </div>
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              /* onClick={() => changePagination(PaginationStates.forward)} */
              onClick={async () => {
                await refresh(
                  Math.min(total, pmsResults?.offset + pmsResults?.limit),
                  0,
                  patientNameRef.current?.value || "",
                  statusRef.current?.value || ""
                );
              }}
            >
              <ForwardIcon />
            </div>
          </div>
          <RetryCallsModal
            isLoading={isRetrying}
            isModalOpen={isRetryModalOpen}
            setIsModalOpen={setIsRetryModalOpen}
            numCalls={selectedItems.size}
            retryCalls={retryCalls}
          />
        </>
      )}
    </>
  );
}
export default PMSResultsTable;
