import React, { useState, useMemo, useEffect } from "react";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import Select, { components, OptionProps } from "react-select";
import { Tooltip } from "@material-tailwind/react";
import toast, { Toaster } from 'react-hot-toast';
import Loader from "components/Loader";
import { useLocation } from "react-router-dom";
import { assignCallToAgent, getAgents, getRobodialers } from "apis/agent";
import KeyValueGrid from "./components/key-value-grid";
import { Controller, useForm } from "react-hook-form";
import { getAgentsResponseType } from "types/types";
import DraggableModal from './components/draggable-modal';

const useQuery = () => {
    const location = useLocation();
    return useMemo(() => new URLSearchParams(location.search), [location.search]);
};

export default function QMToolsAssignCallsPage() {
    const [agentsData, setAgentsData] = useState<null | getAgentsResponseType>(null);
    const [sourceData, setSourceData] = useState<null | {
        id: string;
        name: string;
        robodialerName?: string;
        robodialerId?: string;
    }[]>(null);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [btnisLoading, setBtnisLoading] = useState<boolean>(false);

    const { register, watch, handleSubmit, control, setValue, formState: { errors } } = useForm();

    const fetchRobodialers = async () => {
        setLoading(true);
        const { data } = await getRobodialers();
        setSourceData(data?.robodialers || []);
    };

    useEffect(() => {
        fetchRobodialers();
        fetchAgents()
    }, []);

    const assign = async (force:boolean = false) => {
                                
      if(!watch('0.robodialers')?.value || !watch('0.agents')?.value || !watch('call_id')){
          toast.error('All inputs are required', {
              iconTheme: {
              primary: '#F36F82',
              secondary: '#ffffff',
              },
          });
          return
      }
      setBtnisLoading(true)
      const {httpCode, data} =  await assignCallToAgent(
          watch('0.robodialers')?.value,
          watch('call_id'),
          watch('0.agents')?.value,
          force
      )
      setBtnisLoading(false)
      if (httpCode === 200) {
          toast.success('Call assigned successfully', {
              iconTheme: {
                  primary: '#4a43cd',
                  secondary: '#ffffff',
              },
          });
      }else{
          toast.error(httpCode + ' Unable to assign call: ' + data?.error ?? '', {
              iconTheme: {
                  primary: '#F36F82',
                  secondary: '#ffffff',
              },
          });
      }
  }

    const fetchAgents = async () => {
        setLoading(true)
        const { data, httpCode } = await getAgents();
        setLoading(false)
        if(httpCode !== 200){
          toast.error(httpCode + ' Unable to get Agents: ' + data?.error ?? '', {
            iconTheme: {
              primary: '#F36F82',
              secondary: '#ffffff',
              },
          });
        }else{
          setAgentsData(data);
        }
    
      };

    useEffect(() => {
        if(sourceData){
            setLoading(false);
        }
    }, [sourceData]);

    const fetchCalls = async () => {
        setLoading(true)
        //const response = await getCalls();
        setLoading(false)
        /* if (response.httpCode !== 200) {
            toast.error(response.httpCode + ' Unable to get Batches: ' + response.data?.error ?? '', {
                iconTheme: {
                    primary: '#F36F82',
                    secondary: '#ffffff',
                },
            });
            return
        } */
    }


  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props} className="flex items-center">
        <div className="mx-1 ">{props.data.icon}</div>
        <div className="mx-1 font-sans">{props.data.label}</div>
      </components.SingleValue>
    );
  };
  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex items-center group">
          <div className="mx-1 fill-jacarta group-hover:fill-superbill-indigo">{props.data.icon}</div>
          <div className="mx-1">{props.data.label}</div>
        </div>
      </components.Option>
    );
  };
  
  const AutoCSelect = (index, selectOptions, label) => {
    return (
      <>
        <label className="block text-xs font-bold text-gray-700 ">{label}</label>
        <Controller
          name={`${index}`}
          control={control}
          render={({ field }) => (
            <Select
              placeholder={`Select ${label}`}
              components={{
                Option,
                SingleValue: SingleValue,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,

              }}
              menuPortalTarget={document.body}
              styles={{
                singleValue: (provided) => ({
                  ...provided,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  '&:hover': { backgroundColor: '#EFF1F6', cursor: 'pointer' },
                  boxShadow: state.isFocused ? '0px 0px 0px 3px #E4E8FD' : 'none',
                  borderColor: state.isFocused ? '#4a43cd' : '#ffffff',
                  zIndex: 11
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: '15px',
                  fontFamily: 'DM Sans',
                  backgroundColor: state.isFocused ? '#f5f6fb' : '#ffffff',
                  color: state.isFocused ? '#4a43cd' : '#38395B',
                  ':active': {
                    ...baseStyles[':active'],
                    backgroundColor: '#D0D3F2',
                  },
                })
              }}
              isSearchable={true}
              className='bg-white border-1 mb-2 block w-full focus:ring-superbill-indigo rounded text-superbill-jacarta text-base ease-in-out duration-300 border border-superpay-soap'
              {...field}
              options={selectOptions}
            />
          )}
          rules={{ required: false }}
        />
      </>
    )
  }
    return (
        <>
            {loading ? (
                <>
                    <div className="w-full h-full flex justify-center items-center">
                        {" "}
                        <Loader />{" "}
                    </div>
                </>
            ) : null}
            {!loading ? (
                <>
                    <div className="mx-4 mt-5 mb-20">
                       <div className="flex items-center justify-start gap-x-2">
                            <span className="font-jakarta text-superbill-jacarta text-xl font-bold mb-4">
                                Assign Calls
                            </span>
                        </div>
                        {sourceData && agentsData ? (
                            <>
                                <div className="border border-superpay-soap rounded p-6 bg-superbill-anti-flash-white">
                                    <div className="my-2">
                                        {AutoCSelect('0.robodialers', sourceData?.map((e) => {
                                            return { value: e.id, label: e.name }
                                        }), 'Robodialers')}
                                    </div>
                                    <div className="my-2">
                                        {AutoCSelect('0.agents', agentsData?.agents?.map((e) => {
                                            return { value: e.id, label: e.email }
                                        }), 'Agents')}
                                    </div>
                                    <div className="my-2">
                                        <label htmlFor="call_id" className="block text-xs font-bold text-gray-700 ">Call Id</label>
                                        <input id="call_id" type="text"
                                            className={`w-full rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base`}
                                            {...register('call_id')} />
                                        </div>
                                </div>
                            </>
                        ) : null}
                        <div className="flex items-center justify-center w-full mt-3">
                            <button className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4'
                            onClick={() => {
                              assign(false)
                            }}
                            disabled={btnisLoading}
                            >
                            {btnisLoading ? <Spinner /> : <>Assign call</>}
                            </button>
                            {/* <button className=' bg-superbill-ultra-red disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-ultra-red-hover text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4'
                            onClick={() => {
                              setModalIsOpen(true)
                            }}
                            disabled={btnisLoading}
                            >
                            {btnisLoading ? <Spinner /> : <>Force Assign call</>}
                            </button> */}
                        </div>
                    </div>
                </>
            ) : null}
            <DraggableModal
                key={'force-assign-qm-tools'}
                isOpen={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
                title={'Force Assign Warning'}
              >
                <div className="p-4 overflow-y-auto">
                  <div className="h-[500px] overflow-y-auto">
                    <div className="flex flex-col h-full divide-y font-sans">
                      <div className="flex-grow overflow-y-auto p-4">
                          <span className="text-sm text-superbill-jacarta font-jacarta ">
                          This action should only be performed under <strong>exceptional circumstances</strong>, such as when the assigned agent is unavailable or out of the office. Please ensure <strong>the call is not currently in progress</strong> before proceeding.
                          </span>
                      </div>
                      <div className="flex flex-row justify-between px-4 py-3">
                        <button
                          className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
                          onClick={() => { setModalIsOpen(false) }}
                        >
                          Close
                        </button>
                        
                        <button
                          onClick={() => {
                            assign(true)
                            setModalIsOpen(false)
                          }}
                          disabled={btnisLoading}
                          className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'>
                          {btnisLoading ? <Spinner /> : <>Assign call</>}
                        </button>
                      </div>
                    </div>
                  </div>  
                </div>
              </DraggableModal>
        </>
    );
}
