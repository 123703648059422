import React, { useEffect, useState, useContext, useRef } from "react";
import toast from "react-hot-toast";
import { ReactComponent as Spinner } from "assets/button-loading.svg";
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as HangUpIcon } from "assets/phone-hang-up.svg";
import { ReactComponent as NextCallIcon } from "assets/phone-next-call.svg";
import { AssignNextCallInQueueResponse } from "types/types";
import Stopwatch from "./components/stopwatch";
import { handleTelnyxEvent } from "./components/telnyx-logger";
import HungUpTone from "assets/hang-up-tone.mp3";
import HangUpModal from "./components/hangup-modal";

type SidekickCallsContentToolbarProps = {
  getNextCall: () => void;
  initWebCall?: (e: string|null) => void;
  initiateCallIsLoading: boolean;
  discard: () => void;
  callData: null | AssignNextCallInQueueResponse;
  robodialerId: string | null;
  register: any;
  editChecked: boolean;
  initiateWebCallDisabled: boolean;
  phoneNumber: string;
  getNextCallDisabled: boolean;
  showKeypad: boolean;
  toggleKeypad: () => void;
  wsCallData: any;
  isGetCallLoading: boolean;
  setIsGetCallLoading: (e) => void;
  wsSetCallData: (e) => void;
  openIsuesModal: () => void;
  user_email: string;
};
export default function SidekickCallsContentToolbar({
  wsCallData,
  wsSetCallData,
  user_email,
  showKeypad,
  toggleKeypad,
  getNextCallDisabled,
  openIsuesModal,
  initiateWebCallDisabled,
  phoneNumber,
  initiateCallIsLoading,
  editChecked,
  initWebCall,
  getNextCall,
  robodialerId,
  isGetCallLoading,
  setIsGetCallLoading,
  discard,
  callData = null,
  register,
}: SidekickCallsContentToolbarProps) {
  const regex = /^[0-9]+$/;
  
  const stopwatchRef = useRef<{
    start: () => void;
    stop: () => void;
    reset: () => void;
  } | null>(null);
  
  // state to disable a btn
  const [disableBtn, setDisableBtn] = useState(false);
  // reset setDisableBtn to false after 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      if (disableBtn){
        setDisableBtn(false);
      }
    }, 10000);
    return () => clearTimeout(timer);
  }, [disableBtn]);

  const startStopwatch = () => {
    stopwatchRef.current?.start();
  };

  const stopStopwatch = () => {
    stopwatchRef.current?.stop();
  };

  const resetStopwatch = () => {
    stopwatchRef.current?.reset();
  };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      wsCallData &&
      wsCallData?.call?.state === "active"
    ) {
      startStopwatch();
    } else {
      if (callData && wsCallData?.call?.state === "destroy") {
        const audio = new window.Audio(HungUpTone);
        audio.play();
        toast.error("Call has been hung up.", {
          iconTheme: {
            primary: "#F36F82",
            secondary: "#ffffff",
          },
        });
      }
      stopStopwatch();
      resetStopwatch();
    }
  }, [wsCallData?.call?.state]);

  const hangupCall = () => {
    if (wsCallData?.call) {
      const audio = new window.Audio(HungUpTone);
      audio.play();
      handleTelnyxEvent(
        "userAction: agent hang up",
        {
          custom: true,
          bundle_numerator: callData?.bundle_numerator ?? '-',
          bundle_denominator: callData?.bundle_denominator ?? '-',
          bundle_in_progress: (callData?.bundle_numerator ?? 0) < (callData?.bundle_denominator ?? 0),
          bundle_num_calls: callData?.num_bundled_calls ?? '-',
          date: new Date().toISOString(),
        },
        user_email
      );
      wsCallData?.call?.hangup();
    }
  }
  return (
    <>
      <div className="font-sans">
        <div
          className={`w-full flex ${
            callData && wsCallData?.call?.state !== 'active' ? "justify-between" : "justify-center"
          } mx-4 bg-white p-3 rounded border border-superbill-soap shadow `}
        >
          {callData  &&  wsCallData?.call?.state !== 'active' ? (
            <div className="flex gap-x-2 items-center justify-start">
              {robodialerId === "DP7Ngpj5QGfffX07CtU4" ||
              robodialerId === "jJFG0YRMbBxjFkiA4QHa" ? (
                <div className="flex items-center justify-center px-4 py-2 gap-x-2 bg-superbill-banner-red rounded">
                  <InfoIcon className="child:fill-superbill-ultra-red-hover max-w-[14px] ml-2 w-[14px]" />
                  <span className="text-sm text-superbill-jacarta">
                    Phone number MUST be looked up for this call
                  </span>
                </div>
              ) : !editChecked ? (
                <label className="w-full flex items-center font-bold text-superbill-jacarta">
                  Number to call: +1 (
                  {callData?.to?.replace(/-/g, "").slice(0, 3)}){" "}
                  {callData?.to?.replace(/-/g, "").slice(3, 6)}-
                  {callData?.to?.replace(/-/g, "").slice(6, 10)}
                </label>
              ) : null}
              <div className="w-full flex gap-x-2 items-center justify-start">
                {editChecked ? (
                  <input
                    type="text"
                    {...register("phoneNumber")}
                    placeholder="10 DIGITS NUMBER - please double check before initiating the call"
                    maxLength={10}
                    className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base w-full mr-2"
                  />
                ) : null}
                <label className="flex items-center  bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 mr-4">
                  <input
                    type="checkbox"
                    {...register("edit")}
                    className="mr-2 checked:bg-superbill-ultramarine rounded-sm hidden"
                  />
                  {!editChecked ? "Edit phone number" : "Cancel"}
                </label>
              </div>
            </div>
          ) : null}

          <div
            className={`w-full flex items-center ${
              callData ? "justify-end" : "justify-center"
            } px-4 py-2 gap-x-2`}
          >
            {!callData ? (
              <button
                disabled={isGetCallLoading}
                className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center mr-4"
                onClick={async () => {
                  setIsGetCallLoading(true);
                  await getNextCall();
                  setIsGetCallLoading(false);
                }}
              >
                <NextCallIcon className="fill-white mr-2" />
                {isGetCallLoading ? <Spinner /> : <>Get Next Call</>}
              </button>
            ) : null}
            {callData && wsCallData?.call?.state !== "ringing" && wsCallData?.call?.state !== "active" ? (
              <>
                <button
                  className="bg-superbill-ultra-red disabled:bg-superbill-ultra-red/50 font-jakarta hover:bg-superbill-ultra-red-hover text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center"
                  onClick={async () => {
                    setIsGetCallLoading(true);
                    await discard();
                    setIsGetCallLoading(false);
                  }}
                  disabled={!callData}
                >
                  <HangUpIcon className="fill-white mr-2" />
                  {isGetCallLoading ? <Spinner /> : <>Discard call</>}
                </button>
              </>)
              : null}
              {callData &&( callData._initiated || wsCallData?.call?.state === 'active') === false ?
              <>
                  <button
                    className="bg-green-600 disabled:bg-superbill-aero-green font-jakarta hover:bg-green-900 text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
                    disabled={disableBtn || (wsCallData?.call?.state && !['hangup', 'destroy'].includes(wsCallData?.call?.state))}
                    onClick={() => {
                      handleTelnyxEvent(
                        "userAction: Agent initiated call",
                        {
                          custom: true,
                          date: new Date().toISOString(),
                        },
                        user_email
                      );
                      if (initWebCall) initWebCall(null)
                    }}
                  >
                    {initiateCallIsLoading ? <Spinner /> : <>Initiate web call</>}
                  </button>
              </>
            : null}
            {wsCallData?.call?.state === 'active' ? (
              <div className="w-full flex justify-between mt-0.5 mx-4  flex-row-reverse">
                <div className="w-full flex items-center justify-end">
                  <button
                      className="ml-2 bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-1 px-3 text-xs border border-superbill-soap rounded-full ease-in-out duration-300 disabled:bg-superbill-anti-flash-white"
                      onClick={() => {
                        openIsuesModal()
                      }}
                    >
                      Having issues?
                    </button>
                  <div className="flex items-center gap-x-3">
                  <Stopwatch ref={stopwatchRef} />
                  {
                  wsCallData?.call?.state !== "destroy" ? (
                      <div className="bg-superbill-indigo p-2 w-60 rounded border border-superbill-soap shadow text-white flex items-center">
                        <div className="h-[12px] w-[12px] bg-white animate-pulse rounded-full mr-2"></div>
                        <div>Call is {wsCallData?.call?.state}</div>
                      </div>
                  ) : (
                    <div className="bg-superbill-anti-flash-white p-2 w-60 rounded border border-superbill-soap">
                      Waiting for next call
                    </div>
                  )}
                  </div>
                </div>
                <div className="flex">
                  {/* <button
                    disabled={
                      !wsCallData?.call || wsCallData?.call?.state !== "ringing"
                    }
                    className="mr-4 bg-superbill-ultramarine disabled:bg-superbill-ultramarine/50 font-jakarta hover:bg-superbill-ultramarine-hover text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center"
                    type="button"
                    onClick={() => {
                      setStartTime(new Date().toISOString());
                      wsCallData?.call?.answer();
                      handleTelnyxEvent(
                        "userAction: agent answer",
                        {
                          custom: true,
                          date: new Date().toISOString(),
                        },
                        user_email
                      );
                    }}
                  >
                    <HangUpIcon className="fill-white mr-2" />
                    {" Answer"}
                  </button> */}
                    <button
                      disabled={
                        !wsCallData?.call || wsCallData?.call?.state === "destroy"
                      }
                      className="mr-4 bg-superbill-ultra-red disabled:bg-superbill-ultra-red/50 font-jakarta hover:bg-superbill-ultra-red-hover text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300 flex items-center"
                      onClick={() => {
                        if((callData?.bundle_numerator ?? 0) < (callData?.bundle_denominator ?? 0)){
                          setIsModalOpen(true);
                        }else{
                          hangupCall();
                        }
                      }}
                    >
                      <HangUpIcon className="fill-white mr-2" />
                      Hang Up
                    </button>
                    {showKeypad ? (
                      <button
                        className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 mr-4"
                        onClick={() => toggleKeypad()}
                      >
                        Hide Keypad
                      </button>
                    ) : (
                      <button
                        className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300 mr-4"
                        onClick={() => toggleKeypad()}
                      >
                        Show Keypad
                      </button>
                    )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <HangUpModal isOpen={isModalOpen} onClose={() => {
        setIsModalOpen(false);
      }} onSuccess={() => {
        hangupCall();
        setIsModalOpen(false);
      }}/>
    </>
  );
}
