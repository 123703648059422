import React, { useState } from 'react';

const Accordion = ({ title, content, isOpened=false }) => {
  const [isOpen, setIsOpen] = useState(isOpened);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="">
      <div
        className="flex items-center justify-between px-2 py-1 cursor-pointer"
        onClick={toggleAccordion}
      >
        <div className="flex items-center w-full justify-between hover:bg-superbill-anti-flash-white rounded px-2 py-1 ease-in-out duration-150">
          <div className="overflow-hidden" style={{ maxWidth: '80%' }}>
            <h3 className="text-base font-semibold text-superbill-jacarta font-sans">{title}</h3>
          </div>
          <div className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="px-4 py-2">{content}</div>
      )}
    </div>
  );
};

export default Accordion;
