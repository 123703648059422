import React, { useEffect, useMemo, useState } from 'react'
import Loader from 'components/Loader';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { ReactComponent as ExclamationIcon } from 'assets/exclamation.svg';
import { DateTime } from 'luxon';
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { ReactComponent as NoPatients } from "assets/no-new-patients.svg";
import { paginationHelper } from '../utils/pagination';
import { checkAllPatientsInsurance, checkPatientInsurance, fetchInsurance, getPatientsCount, getRobodialerUser } from 'apis/robodialer';
import { Tooltip } from '@material-tailwind/react';
import { toast } from 'react-hot-toast';
import { PMSPatientData } from 'types/types';
import VerifyAllModal from './dashboard/verify-all-modal';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';


interface FormType {
  gotoInput: number;
}

type PMSPatientDataWithLoading = PMSPatientData & {
  loadingInsurance: boolean;
};
type LocationsObject = {
  //locations: { [key: string]: PMSPatientDataWithLoading };
  patients: Array<PMSPatientDataWithLoading>;
  offset: number;
  limit: number;
};
type pmsPatientsTableProps = {
  pmsPatientsData: LocationsObject,
  setPmsPatients: React.Dispatch<React.SetStateAction<any | null>>;
  refresh: (limit?: number, offset?: number, searchValue?: string) => void,
}

type PatientsCount = {
  'total': number;
  'unverified': number;
}

function PmsPatientsTable({ pmsPatientsData, setPmsPatients, refresh }: pmsPatientsTableProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [patientsOverview, setPatientsOverview] = useState<PatientsCount | null>(null);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [verifyAll, setVerifyAll] = useState<boolean>(false); 
  
  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);

  const [userName, setUserName] = useState<string>('');

  useEffect(() => {
    const fetch = async () => {
      const userResponse = await getRobodialerUser();
      setUserName((userResponse.data.firstName ?? '') + ' ' + (userResponse.data.lastName ?? ''));
      setLoading(false)
    }
    fetch()
  }, []);

  const {
    newEntries: currentActiveResults,
  } = paginationHelper({
    entries: pmsPatientsData?.patients,
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 15,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });

  const fetchInsuranceData = async (id: number) => {
    let newPatients = { ...pmsPatientsData };
    newPatients.patients[id].loadingInsurance = true;
    setPmsPatients(newPatients);
    const { httpCode } = await fetchInsurance(newPatients.patients[id].location_id, newPatients.patients[id].id, newPatients.patients[id].ehr_id)
    if (httpCode === 200) {
      //refresh()
      let patArray = { ...pmsPatientsData };
      patArray.patients[id].loadingInsurance = false;
      patArray.patients[id].missing_insurance = false;
      setPmsPatients(patArray);
      toast.success(`Insurance successfully fetched. You may now verify the patient’s insurance.`, {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      });
    } else {
      toast.error(`Fetching failed. Make sure the patient’s data is in your PMS and try again.`, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      newPatients = { ...pmsPatientsData };
      newPatients.patients[id].loadingInsurance = false;
      setPmsPatients(newPatients);
    }
  };



  const checkInsurance = async (id: number) => {
    let newPatients = { ...pmsPatientsData };

    newPatients.patients[id].loadingInsurance = true
    setPmsPatients(newPatients);
    const { httpCode, data } = await checkPatientInsurance(newPatients.patients[id].location_id, newPatients.patients[id].id, newPatients.patients[id].ehr_id)
    if (httpCode === 200) {
      //refresh()
      navigate('/super-dial-dashboard/pms-insurance-verifications')
    } else {
      let msg = 'Unable to Verify. ' + data?.error ?? ''
      if(httpCode === 409){
        msg = data.error ?? 'Existing call in progress for this patient'
      } 
      toast.error(msg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
      newPatients = { ...pmsPatientsData };
      newPatients.patients[id].loadingInsurance = false
      setPmsPatients(newPatients);
    }
  };


  const checkAll = async (onlySelected?: boolean) => {
    setVerifyAll(true)
    let response
    if (onlySelected) {
      const selectedItemsByLocation: { [key: string]: string[] } = {};
      Array.from(selectedItems ?? []).forEach((itemKey) => {
        const [locationId, patientId] = itemKey.split('-');
        if (!selectedItemsByLocation[locationId]) {
          selectedItemsByLocation[locationId] = [];
        }
        selectedItemsByLocation[locationId].push(patientId);
      })

      response = await checkAllPatientsInsurance(selectedItemsByLocation)
    } else {
      response = await checkAllPatientsInsurance()
    }
    setVerifyAll(false)
    if (response?.httpCode === 200) {
      handleSelectAll()
      toast.success('Verifying patients', {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      }
      );
      setModalIsOpen(false)
      refresh()
    } else {
      toast.error('Unable to Verify. ' + response?.data?.error ?? '', {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }
  };

  const [searchValue, setSearchValue] = useState<null | string>(null);


  useEffect(() => {
    let timeoutId;
    let delay = 1500;
    const fetchData = async () => {
      if (searchValue === null) {
        return
      }
      try {
        setLoading(true)
        const [patientsCountResponse, _] = await Promise.all([
          getPatientsCount(searchValue),
          refresh(0, 15, searchValue)
        ]);

        const { data, httpCode } = patientsCountResponse;

        setPatientsOverview(httpCode === 200 ? (data as PatientsCount) : {
          'total': 0,
          'unverified': 0
        });
        setValue('gotoInput', (pmsPatientsData.offset / pmsPatientsData.limit + 1));
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    clearTimeout(timeoutId);
    timeoutId = setTimeout(fetchData, delay);

    return () => clearTimeout(timeoutId);
  }, [searchValue]);



  const [selectAll, setSelectAll] = useState(false); // 
  //const [selectedItems, setSelectedItems] = useState<{locationId:number, itemId:number}[]>([]); 
  const [selectedItems, setSelectedItems] = useState(new Set<string>());

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set<string>();

      (pmsPatientsData?.patients ?? [])?.forEach((patient) => {
        const itemKey = `${patient.location_id}-${patient.id}`;
        if (patient && patient.missing_insurance !== 'true' && !prevSelectedItems.has(itemKey)) {
          newSelectedItems.add(itemKey);
        }
        
      });

      return newSelectedItems;
    });
  };




  const handleSelectItem = (locationId, patientId) => {
    const key = `${locationId}-${patientId}`;
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (newSelectedItems.has(key)) {
        newSelectedItems.delete(key);
      } else {
        newSelectedItems.add(key);
      }
      return newSelectedItems;
    });
  };

  const { setValue, control } = useForm<FormType>();

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    
    const newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      setValue('gotoInput', newValue);
      if (0 <= (newValue - 1) && (newValue - 1) < Math.ceil(patientsOverview?.total! / pmsPatientsData?.limit)) {
        setLoading(true)
        //console.log((newValue - 1) * pmsPatientsData?.limit, newValue - 1, pmsPatientsData?.limit);
        await refresh(((newValue - 1) * pmsPatientsData?.limit), 0, searchValue!)
        setValue('gotoInput', (pmsPatientsData.offset / pmsPatientsData.limit + 1));
        setLoading(false)
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }

  };
  useEffect(() => {
    const fetch = async () => {
      const { data, httpCode } = await getPatientsCount(searchValue ?? '');
      setPatientsOverview(httpCode === 200 ? (data as PatientsCount) : {
        'total': 0,
        'unverified': 0
      })
      setValue('gotoInput', (pmsPatientsData.offset / pmsPatientsData.limit + 1));
    }
    fetch()
  }, [])

  const parseDOB = (dob) => {
    // Try to parse the date as an ISO string first
    let date = DateTime.fromISO(dob);
    
    // If it's not valid, try other formats
    if (!date.isValid) {
      const formats = ['MM-dd-yyyy'];
      
      for (const format of formats) {
        date = DateTime.fromFormat(dob, format);
        if (date.isValid) {
          break;
        }
      }
    }
  
    // Return the formatted date or a fallback if the date is invalid
    return date.isValid ? date.toFormat("MMMM dd, yyyy") : 'Invalid Date';
  };
  /* useEffect(() => {
    if (pmsPatientsData) {
      setValue('gotoInput', (pmsPatientsData.offset / pmsPatientsData.limit + 1));
    }
  }, [pmsPatientsData]) */

  return (
    <>
      {/* <PmsOverviewCard missingPatients={Object.keys(sortedPatients ?? {}).length} /> */}
      <div className='text-superbill-jacarta font-jakarta font-semibold text-xl'>
        Patients
      </div>

      <div className="w-full flex items-center justify-between font-sans  mb-4">
        <div className='flex items-center justify-end'>
          <button 
          disabled={0==selectedItems.size} 
          className={`${0 < pmsPatientsData?.patients?.length && !loading ? 'visible hover:bg-superbill-lavender-dark/50 ease-in-out duration-300' : 'invisible'} flex items-center px-5 py-2 mr-1 truncate border border-superbill-soap rounded-full font-jakarta text-superbill-jacarta disabled:bg-superbill-wild-blue-yonder/20 disabled:text-superbill-jacarta/50` }
          onClick={() => {
            checkAll(true)
          }}
          >
            <span className='font-semibold text-sm'>Verify Selected {0<selectedItems.size ? `(${selectedItems.size})` : ''}</span>
          </button>
          {/* <button className={`${0 < Object.keys(sortedPatients ?? {}).length && !loading ? 'visible hover:bg-superbill-lavender-dark/50 ease-in-out duration-300' : 'invisible'} flex items-center px-5 py-2 truncate border border-superbill-soap rounded-full font-jakarta mr-2`}><span className='flex items-center text-superbill-jacarta font-semibold text-sm' onClick={() => {
            setModalIsOpen(true)
          }}>Verify All {patientsOverview && patientsOverview.unverified ? <>{patientsOverview.unverified}</> : <Loader size={12} />}</span></button> */}
        </div>
        <div className="w-full flex px-2 items-center justify-end font-sans">
          <input
            disabled={loading}
            type="text"
            placeholder="Search by patient name"
            value={searchValue!}
            onChange={(e) => setSearchValue(e.target.value)}
            className="w-2/3 rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base mr-1"
          />
          {/* <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value as 'all' |  'completed' | 'failed')}
            className='bg-white border-superbill-soap block focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded text-superbill-jacarta text-base w-1/3'
          >
            <option value="all">All verification statuses</option>
            <option value="completed">Verified</option>
            <option value="failed">Not Verified</option>
          </select> */}
        </div>
      </div>

      <div className="border border-superbill-soap divide-y divide-superbill-soap rounded w-full">
        {loading ? (
          <>
            <div className='w-full h-full flex justify-center items-center p-5'>
              {' '}
              <Loader />{' '}
            </div>
          </>
        ) : null}
        {!loading ? (
          <>
            {0 === pmsPatientsData?.patients?.length ?
              <div className='text-center text-superbill-jacarta text-base p-5'>
                <NoPatients className="w-full text-center mb-4" />No patients detected</div>
              : null}
            {0 < pmsPatientsData?.patients?.length ?
              <>
                <div className="grid grid-cols-14 divide-x divide-superbill-soap font-sans">
                  <div className="col-span-1 bg-superbill-anti-flash-white px-2 py-1 rounded-tl">
                    <div className='flex w-full h-full justify-center items-center'>
                      <input
                        type="checkbox"
                        className="rounded-sm checked:border-superbill-ultramarine"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </div>
                  </div>
                  <div className="col-span-2 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Appointment Date</span>
                    </div>
                  </div>
                  {/* <div className="col-span-2 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Date added</span>
                    </div>
                  </div> */}
                  <div className="col-span-2 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        First Name</span>
                    </div>
                  </div>
                  <div className="col-span-2 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Last Name</span>
                    </div>
                  </div>
                  <div className="col-span-2 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Date of Birth</span>
                    </div>
                  </div>
                  <div className="col-span-2 bg-superbill-anti-flash-white px-2 py-1">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Location</span>
                    </div>
                  </div>
                  <div className="col-span-3 bg-superbill-anti-flash-white px-2 py-1 rounded-tr">
                    <div className='flex w-full h-full justify-left items-center'>
                      <span className='text-sm text-superbill-jacarta font-bold'>
                        Last Verified by SuperDial</span>
                    </div>
                  </div>
                </div>
                <div className="divide-y divide-superbill-soap overflow-hidden w-full bg-superbill-table-grey rounded-b font-sans">
                  {/* {Object.keys(sortedPatients).length === 0 ? <>
                  <div className='text-center text-superbill-jacarta text-base p-5'>
                    <NoPatients className="w-full text-center mb-4" />No patients detected</div>
                </> : null} */}
                  <>
                    {pmsPatientsData?.patients.map((patient, index) => {
                      return (
                        <div className="grid grid-cols-14 divide-x divide-superbill-soap bg-white hover:bg-superbill-lavender/50  ease-in-out duration-150 last:rounded-b w-full" key={`${patient.location_id}-${patient.id}`}>
                          <div className="col-span-1 px-2">
                            <div className='flex w-full h-full justify-center items-center'>
                              {patient.missing_insurance === 'false' || patient.missing_insurance === false ?
                                <>
                                  <input
                                    type="checkbox"
                                    className="border border-superpay-light-text-gray rounded-sm checked:border-superbill-ultramarine"
                                    checked={selectedItems.has(`${patient.location_id}-${patient.id}`)}
                                    onChange={() => handleSelectItem(patient.location_id, patient.id)}
                                  />
                                </>
                                : 
                                <>
                                  { patient.lastModifiedAt ? 
                                    <Tooltip
                                      content={
                                        <span style={{ whiteSpace: 'pre-line' }}>
                                          Failed to get insurance data. Update the patient’s insurance data in your PMS and click “Fetch Insurance”.
                                        </span>
                                      } className='bg-black py-1 px-2'>
                                      <ExclamationIcon className='child:fill-[#F46F83] min-w-[16px] max-w-[16px]' />
                                    </Tooltip> : <input
                                      type="checkbox"
                                      className="border border-superpay-light-text-gray rounded-sm checked:border-superbill-ultramarine disabled:bg-superbill-light-gray disabled:boder-superbill-light-gray"
                                      disabled={true}
                                    />
                                  }
                                </>
                              }
                            </div>
                          </div>
                          <div className="col-span-2 px-2">
                            <div className='flex w-full h-full justify-left items-center overflow-auto'>
                              {patient?.next_appointment_start_time &&
                                <div className='flex gap-x-1 items-center flex-shrink-0  truncate'><span className='text-sm text-superbill-jacarta'>{DateTime.fromJSDate(new Date(patient?.next_appointment_start_time)).toFormat('MMMM d, yyyy')}</span>
                                </div>
                              }
                              {!patient?.next_appointment_start_time && patient?.last_appointment_start_time &&
                                <div className='flex-shrink-0  truncate'><span className='text-sm text-superbill-jacarta'>{DateTime.fromJSDate(new Date(patient?.last_appointment_start_time)).toFormat('MMMM d, yyyy')}</span></div>
                              }
                              { !patient?.next_appointment_start_time && !patient?.last_appointment_start_time ? <span className='text-sm text-superbill-jacarta'>-</span> : null}
                            </div>
                          </div>
                          {/* <div className="col-span-2 px-2">
                            <div className='flex w-full h-full justify-left items-center overflow-hidden'>
                              <div className='flex items-center flex-shrink-0'><span className='text-sm text-superbill-jacarta'>{DateTime.fromJSDate(new Date(patient.created_at)).toFormat('MMMM d, yyyy')}</span>
                              </div>
                            </div>
                          </div> */}
                          <div className="col-span-2 px-2">
                            <div className='flex w-full h-full justify-left items-center overflow-auto'>
                              <div className=' flex-shrink-0  truncate'><span className='text-sm text-superbill-jacarta'>{patient.first_name}</span></div></div>
                          </div>
                          <div className="col-span-2 px-2">
                            <div className='flex w-full h-full justify-left items-center overflow-auto'>
                              <div className=' flex-shrink-0  truncate'><span className='text-sm text-superbill-jacarta'>{patient.last_name}</span></div></div>
                          </div>
                          <div className="col-span-2 px-2">
                            <div className='flex w-full h-full justify-left items-center overflow-auto'>
                              <div className=' flex-shrink-0  truncate'><span className='text-sm text-superbill-jacarta'>{parseDOB(patient?.dob.toString())}</span></div></div>
                          </div>
                          <div className="col-span-2 px-2">
                            <div className='flex w-full h-full justify-left items-center overflow-auto'>
                              <div className=' flex-shrink-0  truncate'><span className='text-sm text-superbill-jacarta'>{patient.location_name ?? '-'}</span></div></div>
                          </div>
                          <div className="col-span-3 px-2 py-1">
                            <div className='flex items-center justify-between w-full h-full'>
                              <div className='text-sm text-superbill-jacarta'>{(patient.call_id && patient.lastModifiedAt) ? <div className='cursor-pointer hover-underline-animation' onClick={() => {
                                navigate('/super-dial-dashboard/pms-insurance-verifications?'+patient.call_id)
                              }}>{DateTime.fromJSDate(new Date(patient.call_end ?? patient.lastModifiedAt)).toFormat('MMM d, yyyy')}</div> : '-'}</div>
                              {patient.missing_insurance != false ?
                                <Tooltip
                                  content={
                                    <span style={{ whiteSpace: 'pre-line' }}>
                                      {patient.loadingInsurance || verifyAll ? <>This could take a minute</> : <>We’ll fetch insurance information after you’ve updated patient data in your PMS</>}
                                    </span>
                                  } className='bg-black py-1 px-2'>
                                  <button className='flex items-center px-2 py-1 mx-1 truncate border border-superbill-soap rounded-full hover:bg-superbill-lavender-dark/50 ease-in-out duration-300' onClick={() => { fetchInsuranceData(index) }}><span className='text-superbill-jacarta font-jakarta font-semibold text-xs'>{patient.loadingInsurance || verifyAll ? <div className='flex gap-x-1 items-center justify-center'><span className='font-jacarta text-xs font-bold text-superbill-wild-blue-yonder'>Fetching Data...</span> <Spinner /></div> : 'Fetch Insurance Data'}</span></button>
                                </Tooltip>
                                :
                                <button className='flex items-center px-2 py-1 mx-2 truncate border border-superbill-soap rounded-full hover:bg-superbill-lavender-dark/50 ease-in-out duration-300' onClick={() => { checkInsurance(index) }}><span className='text-superbill-jacarta font-jakarta font-semibold text-xs'>{patient.loadingInsurance || verifyAll ? <Spinner /> : 'Verify Insurance'}</span></button>
                              }
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                </div>
              </>
              : null}
          </>
        ) : null}

      </div>
      <div className='w-full flex items-center justify-between font-sans'>
        <div className='text-sm text-superbill-jacarta'>
          <strong>{patientsOverview?.total.toLocaleString()} Patients</strong>
        </div>
        <>
        </>
        {0 < pmsPatientsData.patients.length ?
          <div className="flex items-center justify-end flex-row px-2 pt-2 font-sans">
            {0 <= (pmsPatientsData.offset - pmsPatientsData.limit) && !loading ?
              <div
                className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
                onClick={async () => {
                  setLoading(true)
                  await refresh(Math.max(0, pmsPatientsData.offset - pmsPatientsData.limit), 0, searchValue!)
                  setValue('gotoInput', (pmsPatientsData.offset / pmsPatientsData.limit + 1));
                  setLoading(false)
                }}
              >
                <BackIcon />
              </div>
              : null}
            <div className="mx-2 flex items-center">
              <strong className='mr-1'>{/* {(pmsPatientsData.offset / pmsPatientsData.limit) + 1} */}
                <Controller
                  name="gotoInput"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      defaultValue={(pmsPatientsData.offset / pmsPatientsData.limit + 1).toString()}
                      className={`px-1 h-6 flex items-center justify-center text-center ronded rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base ${pmsPatientsData.offset > 999 ? `w-16` : pmsPatientsData.offset > 99 ? `w-12` : `w-8`}`}
                      onBlur={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />
                  )}
                />
                {/* {"to "} */}</strong> {patientsOverview && patientsOverview.total ? <>
                  {/* <strong className='mr-1'>{" "}{Math.min(patientsOverview?.total!, (pmsPatientsData.offset) + pmsPatientsData.limit)}</strong> */} of{" "} {(Math.ceil(patientsOverview?.total! / pmsPatientsData?.limit)-1).toLocaleString()}
                </> : <Loader size={12} />}
            </div>
            {(pmsPatientsData.offset + pmsPatientsData.limit) < patientsOverview?.total! && !loading ?
              <div
                className="cursor-pointer fill-superbill-gray-2 hover:fill-superbill-ultramarine"
                onClick={async () => {
                  setLoading(true)
                  await refresh(Math.min(patientsOverview?.total!, pmsPatientsData.offset + pmsPatientsData.limit), 0, searchValue!)
                  setValue('gotoInput', (pmsPatientsData.offset / pmsPatientsData.limit + 1));
                  setLoading(false)
                }}
              >
                <ForwardIcon />
              </div>
              : null}
          </div>
          : null}
      </div>
      <VerifyAllModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} unverified={patientsOverview?.unverified!} onCancel={() => { setModalIsOpen(false) }} onConfirm={async () => {
        await checkAll(false)
      }} />
    </>
  )
}
export default PmsPatientsTable;